import {Article} from 'components/Article';
import {List} from 'components/List';
import {SEO} from 'components/SEO';
import {graphql, PageProps} from 'gatsby';
import {Link, Trans, useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react';

const Sla: React.FC = () => {
  return (
    <Article wide>
      <SEO
        path="sla"
        title="MONEI Compute Service Level Agreement"
        description="This MONEI Compute Service Level Agreement (this “SLA”) is a policy governing the use of the Included Services."
      />
      <h1>MONEI Compute Service Level Agreement</h1>
      <p>
        This MONEI Compute Service Level Agreement (this “SLA”) is a policy governing the use of the
        Included Services (listed below) and applies separately to each account using the Included
        Services. In the event of a conflict between the terms of this SLA and the terms of the
        digitally signed contract by the “merchant” & MONEI or other agreement with us governing
        your use of our Services (the “Agreement”), the terms and conditions of this SLA apply, but
        only to the extent of such conflict. Capitalized terms used herein but not defined herein
        shall have the meanings set forth in the Agreement.
      </p>
      <p>Included Services:</p>
      <List>
        <li>MONEI Dashboard</li>
        <li>MONEI REST API</li>
        <li>MONEI GraphQL API</li>
      </List>
      <p>
        Upon Consumer agreement to MONEI Terms, MONEI secures and protects the cardholder data
        according to the currently applicable PCI standard for the life of the data needing to be
        retained. MONEI acknowledges these responsibilities as being the organization responsible
        for ensuring the safe handling and storage of sensitive customer credit card information and
        data for the MONEI services.
      </p>
      <h3>SLAs</h3>
      <p>
        MONEI will use commercially reasonable efforts to make each Included Service available for
        each MONEI account with a Monthly Uptime Percentage of at least 99.99%, in each case during
        any monthly billing cycle. In the event any of the Included Services do not meet the account
        SLA, you will be eligible to receive a Service Credit as described below.
      </p>
      <p>
        Monthly Uptime Percentage: Less than 99.99% but equal to or greater than 99.0% {'-->'} 10%
        Service Credit Percentage
      </p>
      <p>
        Monthly Uptime Percentage: Less than 99.00% but equal to or greater than 95.0% {'-->'} 30%
        Service Credit Percentage
      </p>
      <p>Monthly Uptime Percentage: Less than 95% {'-->'} 100% Service Credit Percentage</p>
      <h3>SLA Credits</h3>
      <p>
        Service Credits are calculated as a percentage of the weekly bill (excluding one-time
        payments such as upfront payments made for buying a POS or QR Stickers pack) for the
        individual Included Service in the affected MONEI account that did not meet the SLA.
      </p>
      <p>
        We will apply any Service Credits only against future payments for the applicable Included
        Service otherwise due from you. At our discretion, we may issue the Service Credit to the
        IBAN you used to pay for the billing cycle in which the Unavailability occurred. Service
        Credits will not entitle you to any refund or other payment from MONEI. A Service Credit
        will be applicable and issued only if the credit amount for the applicable weekly billing
        cycle is greater than one dollar (€1 EUR). Service Credits may not be transferred or applied
        to any other account.
      </p>
      <h3>MONEI SLA Exclusions</h3>
      <p>
        The SLA does not apply to any unavailability, suspension or termination of an Included
        Service, or any other Included Service performance issues, directly or indirectly: (i)
        caused by factors outside of our reasonable control, including any force majeure event or
        Internet access or related problems beyond the demarcation point of the applicable Included
        Service; (ii) that result from any actions or inactions of you or any third party, including
        failure to acknowledge a recovery volume or respond to resource health concerns; (iii) that
        result from your equipment, software or other technology and/or third party equipment,
        software or other technology (other than third party equipment within our direct control);
        or (iv) arising from our suspension or termination of your right to use the applicable
        Included Service in accordance with the Agreement (collectively, the “MONEI SLA
        Exclusions”). If availability is impacted by factors other than those used in our Weekly
        Uptime Percentage calculation, then we may issue a Service Credit considering such factors
        at our discretion.{' '}
      </p>

      <p>
        MONEI Terms of Service are available <Link to="/terms-of-service/">here</Link>.
      </p>
    </Article>
  );
};

export default Sla;
